import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'; 
import { auth } from './firebase'; 
import './LandingPage.css';
import image1 from './img1.png';
import image2 from './img2.png';
import image3 from './img3.png';

const slides = [
  { src: image1, bgColor: '#F8E1DA', text: 'Trained on course material!' }, 
  { src: image2, bgColor: '#D8E8E4', text: 'Insert generic inspirational quote here that I cannot come up with' }, 
  { src: image3, bgColor: '#F9E79F', text: 'The color yellow is mid' }  
];

const LandingPage = () => {
  const [isSignUp, setIsSignUp] = useState(false); 
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);
  const [error, setError] = useState(''); // State for error messages

  const handleToggleForm = (formType) => {
    setIsSignUp(formType === 'signup');
    setIsForgotPassword(formType === 'forgot');
    setError(''); // Clear error messages when switching forms
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idTokenResult = await userCredential.user.getIdTokenResult();
      const claims = idTokenResult.claims;

      // Check if user has necessary privileges
      if (claims.student || claims.TF || claims.developer || claims.admin) {
        window.location.href = '/GPT'; // Redirect to /GPT if user has the correct claims
      } else {
        setError('Permissions invalid, you are on the waitlist.');
      }
    } catch (err) {
      setError('Incorrect email or password. Please try again.');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setIsProgressBarVisible(false); // Hide progress bar during transition

      setTimeout(() => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        setIsTransitioning(false);
        setIsProgressBarVisible(true); // Show progress bar after transition
      }, 1000); // Transition time
    }, 10000); // Slower animation interval

    return () => clearInterval(interval);
  }, []);

  const { src, bgColor, text } = slides[currentSlideIndex];

  return (
    <div className="landing-page">
      <div className="landing-card">
        <div className={`graphic-side ${isTransitioning ? 'transitioning' : ''}`} style={{ backgroundColor: bgColor }}>
          <div className="graphic-content">
            <img src={src} alt="Placeholder" className="rotating-image" />
            <p>{text}</p>
          </div>
          {isProgressBarVisible && <div className="progress-bar"></div>}
        </div>
        <div className="login-side">
          <div className={`form-container ${isSignUp ? 'slide-left' : isForgotPassword ? 'slide-right' : ''}`}>
            <form className="login-form" onSubmit={handleSignIn}>
              <h2>Harvard Chatbot, 2.0.1(revert)</h2>
              <div className="spacer"></div>
              <label>Email</label>
              <input name="email" type="email" placeholder="Your Harvard or MIT Email" required />
              <label>Password</label>
              <input name="password" type="password" placeholder="Your password" required />
              <div className="forgot-password-container">
                <a onClick={() => handleToggleForm('forgot')} className="forgot-password">Forgot password?</a>
              </div>
              <button type="submit" className="login-button">Sign In</button>
              <div className="create-account">
                <a onClick={() => handleToggleForm('signup')} className="create-account-link">New here? Create an account!</a>
              </div>
              {error && <p className="error-message">{error}</p>} {/* Display error message */}
            </form>
            <form className="signup-form">
              <h2>Sign Up</h2>
              <label>Name</label>
              <input type="text" placeholder="Your Full Name" required />
              <label>Course ID</label>
              <input type="text" placeholder="Your Course ID" required />
              <label>Email</label>
              <input type="email" placeholder="Your Harvard or MIT Email" required />
              <div className="password-fields">
                <div>
                  <label>Password</label>
                  <input type="password" placeholder="Create a password" required />
                </div>
                <div className="confirm-password">
                  <label>Confirm Password</label>
                  <input type="password" placeholder="Confirm your password" required />
                </div>
              </div>
              <button type="submit" className="login-button">Sign Up</button>
              <div className="create-account">
                <a onClick={() => handleToggleForm('login')} className="create-account-link">Already have an account? Return.</a>
              </div>
            </form>
            <form className="forgot-password-form">
              <h2>Forgot Password</h2>
              <label>Email</label>
              <input type="email" placeholder="Your Harvard or MIT Email" required />
              <button type="submit" className="login-button">Send Recovery Email</button>
              <div className="create-account">
                <a onClick={() => handleToggleForm('login')} className="create-account-link">Memory spark? Go back!</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

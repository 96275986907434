import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';

const RiemannSurfacePlot = ({ funcExpression, plotMode, initialPlotData }) => {
  const [plotData, setPlotData] = useState(initialPlotData || null);  // Start with initial plot data if provided
  const [errorMessage, setErrorMessage] = useState('');  // State for error messages
  const [loading, setLoading] = useState(true);  // State for loading

  // Fetch new plot data when funcExpression or plotMode changes
  useEffect(() => {
    // If no new function expression or mode changes, use initial data
    if (initialPlotData) {
      setPlotData(initialPlotData);
      setLoading(false);
      return;
    }

    // Function to fetch data from Flask backend
    const fetchData = async () => {
      setLoading(true); // Set loading state
      try {
        const response = await axios.post('https://gptharvard.uc.r.appspot.com/generate_data', {
          funcExpression: funcExpression,
          plotMode: plotMode,
        });
        
        if (response.data.error) {
          console.error('Evaluation Error:', response.data.error);
          setErrorMessage(response.data.error);  // Set the error message
          setLoading(false);
          return;
        }

        // Update the plot data
        setPlotData(response.data);
        setErrorMessage('');  // Clear any previous errors
      } catch (error) {
        console.error('Error fetching plot data:', error);
        setErrorMessage('Error fetching data from the server.');
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchData();
  }, [funcExpression, plotMode, initialPlotData]);

  // Handle the case when there's no plot data yet or an error occurs
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!plotData) {
    return <div>No data available</div>;
  }

  const { real, imag, real_w, imag_w, color_scale } = plotData;

  // Validate the structure of the received data
  if (!Array.isArray(real) || !Array.isArray(imag) || !Array.isArray(real_w) || !Array.isArray(imag_w)) {
    return <div>Error: Data format is incorrect.</div>;
  }

  return (
    <div>
      {/* Display error messages if present */}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

      {/* Plot for the Real part of w */}
      <Plot
        data={[
          {
            x: real,  // Real parts of z
            y: imag,  // Imaginary parts of z
            z: real_w,  // Real part of w
            type: 'surface',
            colorscale: 'Viridis',
            intensity: color_scale,
          }
        ]}
        layout={{ 
          title: 'Re(w) - Riemann Surface Visualization',
          scene: {
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
            zaxis: { title: 'Re(f(z))' },
          }
        }}
      />

      {/* Plot for the Imaginary part of w */}
      <Plot
        data={[
          {
            x: real,  // Real parts of z
            y: imag,  // Imaginary parts of z
            z: imag_w,  // Imaginary part of w
            type: 'surface',
            colorscale: 'Viridis',
            intensity: color_scale,
          }
        ]}
        layout={{ 
          title: 'Im(w) - Riemann Surface Visualization',
          scene: {
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
            zaxis: { title: 'Im(f(z))' },
          }
        }}
      />
    </div>
  );
};

export default RiemannSurfacePlot;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "./LandingPage";
import NewGPT from './NewGPT'; // Main Page for the Current GPT Interface (2.0)
import NewerGPT from './NewerGPT'; // Main Page for the Current GPT Interface (3.0)
import AdminPage from "./AdminPage"; 
import PrivacyPolicyOverlay from './PrivacyPolicyOverlay';
import './App.css';
import { auth } from './firebase';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showPrivacyOverlay, setShowPrivacyOverlay] = useState(false);
    const [userInfo, setUserInfo] = useState({ firstName: '', roles: [], courses: [] });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                const claims = idTokenResult.claims;

                const firstName = claims.firstName || 'User';
                const roles = ['admin','instructor', 'TF', 'developer', 'student'].filter(role => claims[role]);
                const courses = claims.courses || [];

                setUser(user);
                setUserInfo({ firstName, roles, courses });
            } else {
                setUser(null);
                setUserInfo({ firstName: '', roles: [], courses: [] });
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const openPrivacyOverlay = () => setShowPrivacyOverlay(true);
    const closePrivacyOverlay = () => setShowPrivacyOverlay(false);

    if (loading) {
        return <div>Loading...</div>; // Optional: Add a spinner or loading animation
    }

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route 
                        path="/GPT" 
                        element={
                            user ? (
                                <NewGPT 
                                    openPrivacyOverlay={openPrivacyOverlay} 
                                    userInfo={userInfo} 
                                />
                            ) : (
                                <Navigate to="/" replace={true} />
                            )
                        }
                    />
                    <Route 
                        path="/admin" 
                        element={
                            user ? (
                                <AdminPage userInfo={userInfo} />
                            ) : (
                                <Navigate to="/" replace={true} />
                            )
                        }
                    />
                    <Route 
                        path="/NewGPT" 
                        element={
                            user ? (
                                <NewGPT 
                                    openPrivacyOverlay={openPrivacyOverlay} 
                                    userInfo={userInfo} 
                                />
                            ) : (
                                <Navigate to="/" replace={true} />
                            )
                        }
                    />
                     <Route 
                        path="/NewerGPT" 
                        element={
                            user && userInfo.roles.includes('admin')? (
                                <NewerGPT 
                                    openPrivacyOverlay={openPrivacyOverlay} 
                                    userInfo={userInfo} 
                                />
                            ) : (
                                <Navigate to="/" replace={true} />
                            )
                        }
                    />
                </Routes>
            </Router>
            {showPrivacyOverlay && <PrivacyPolicyOverlay onClose={closePrivacyOverlay} />}
        </div>
    );
}

export default App;
import React, { useState, useEffect, useRef, useCallback, useMemo, Suspense } from 'react';
import ReactFlow, { addEdge, Handle, Background, Controls, MiniMap } from 'react-flow-renderer';
import { FaCopy, FaFlag, FaPaperPlane, FaSyncAlt } from 'react-icons/fa';
import dagre from 'dagre';
import io from 'socket.io-client';
import 'katex/dist/katex.min.css';
import renderTextWithFormatting from './renderTextWithFormatting';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import Plot from 'react-plotly.js';
import './App.css';

// Import your visualization components
import RiemannSurfacePlot from './RiemannSurfacePlot'; // Ensure this path is correct

// Photos needed for the file.
import newImage1 from './img1.png';
import newImage2 from './img2.png';
import newImage3 from './img3.png';
import harvardLogoAM104 from './AM104.png';
import harvardLogoPhysics from './Physics.png'; // Replace with the actual path to your physics class image

// Define the initial options outside the component
const initialOptions = ['', '', ''];

function NewerGPT({
  openPrivacyOverlay,
  openTutorialOverlay,
  openExamplesOverlay,
  userInfo = { firstName: 'User', roles: [], courses: [] },
  status = 'Connected',
}) {
  const [selectedClass, setSelectedClass] = useState(userInfo.courses[0] || 'default_class');

  const getClassLogo = () => {
    if (selectedClass === 'AM104') {
      return harvardLogoAM104;
    } else if (selectedClass === 'physics15a') {
      return harvardLogoPhysics;
    } else {
      return harvardLogoAM104; // Default image
    }
  };

  // Refs and State Variables
  const socketRef = useRef();
  const nextIdRef = useRef(1);
  const resizingNodeRef = useRef(null);
  const flowRef = useRef(null);
  const navigate = useNavigate(); // For logout

  // State for reactFlowInstance
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [input, setInput] = useState('');

  // Night mode state
  const [isNightMode, setIsNightMode] = useState(false);

  // State for settings modal
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Function to toggle night mode
  const toggleNightMode = () => {
    setIsNightMode((prevMode) => !prevMode);
  };

  // Function to get the next unique ID
  const getNextId = () => {
    const id = nextIdRef.current.toString();
    nextIdRef.current += 1;
    return id;
  };

  // Function to send user message
  const addUserMessage = useCallback(
    (messageText = input) => {
      if (messageText.trim()) {
        // Emit the message to the server
        socketRef.current.emit('add_message', {
          message: messageText,
          token: localStorage.getItem('token'), // Include token if necessary
        });

        // Create user node
        const userId = getNextId();
        const userNode = {
          id: userId,
          type: 'user',
          data: { label: `${messageText}` },
          position: { x: 0, y: 0 }, // Position will be set by layout
        };

        // Create bot node with empty label and assign isPlaceholder
        const botNodeId = getNextId();
        const botNode = {
          id: botNodeId,
          type: 'chatbot',
          data: { label: '', hasVisual: false, isPlaceholder: true },
          position: { x: 0, y: 0 }, // Position will be set by layout
        };

        // Update nodes
        setNodes((prevNodes) => {
          // Remove initial options node if it exists
          const updatedNodes = prevNodes.filter((node) => node.id !== 'initial-options');
          updatedNodes.push(userNode, botNode);
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });

        // Update edges
        setEdges((prevEdges) => {
          const updatedEdges = [...prevEdges];

          // Edge from user node to bot node
          updatedEdges.push({
            id: `e${userId}-${botNodeId}`,
            source: userId,
            target: botNodeId,
            type: 'smoothstep',
            animated: true,
          });

          // Edge from previous bot node to current user node (if any)
          const prevBotNode = nodesRef.current
            .slice(0, -2) // Exclude the last two nodes (current user and bot nodes)
            .reverse()
            .find((node) => node.type === 'chatbot');

          if (prevBotNode) {
            updatedEdges.push({
              id: `e${prevBotNode.id}-${userId}`,
              source: prevBotNode.id,
              target: userId,
              type: 'smoothstep',
              animated: true,
            });
          }

          edgesRef.current = updatedEdges;
          return updatedEdges;
        });

        // Clear input
        setInput('');
      }
    },
    [input]
  );

  // Memoize handleOptionClick to prevent re-renders
  const handleOptionClick = useCallback(
    (optionText) => {
      addUserMessage(optionText);
    },
    [addUserMessage]
  );

  // Initialize nodes with the initial options node
  const [nodes, setNodes] = useState([
    {
      id: 'initial-options',
      type: 'initialOptions',
      data: { onOptionClick: handleOptionClick },
      position: { x: 0, y: 0 }, // Position will be set by layout
    },
  ]);
  const [edges, setEdges] = useState([]);
  const [transform] = useState({ x: 0, y: 0, zoom: 1 });

  // Reference to the latest nodes and edges
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Update refs when state changes
  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  // Use dagre layout
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 500; // Use the node width we set in CSS
  const nodeHeight = 100; // Approximate node height

  const getLayoutedElements = useCallback(
    (nodesWithDimensions, edges, direction = 'TB') => {
      const isHorizontal = direction === 'LR';
      dagreGraph.setGraph({ rankdir: direction, nodesep: 20, ranksep: 40 });

      // Separate main nodes and reference nodes
      const mainNodes = nodesWithDimensions.filter((node) => node.type !== 'reference');
      const refNodes = nodesWithDimensions.filter((node) => node.type === 'reference');

      // Filter out edges connected to reference nodes
      const mainEdges = edges.filter((edge) => {
        const sourceNode = nodesWithDimensions.find((n) => n.id === edge.source);
        const targetNode = nodesWithDimensions.find((n) => n.id === edge.target);
        return sourceNode.type !== 'reference' && targetNode.type !== 'reference';
      });

      // Set nodes with actual dimensions
      mainNodes.forEach((node) => {
        const width = node.width || nodeWidth;
        const height = node.height || nodeHeight;
        dagreGraph.setNode(node.id, { width, height });
      });

      // Set edges for the main nodes
      mainEdges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      // Update positions of main nodes
      const layoutedNodes = mainNodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
          x: nodeWithPosition.x - (node.width || nodeWidth) / 2,
          y: nodeWithPosition.y - (node.height || nodeHeight) / 2,
        };

        return node;
      });

      // Now adjust the positions of the reference nodes
      refNodes.forEach((node) => {
        // Find the source chatbot node
        const incomingEdge = edges.find((edge) => edge.target === node.id && edge.source !== node.id);
        if (incomingEdge) {
          const chatbotNode = layoutedNodes.find((n) => n.id === incomingEdge.source);
          if (chatbotNode) {
            // Position the reference node to the right of the chatbot node
            node.position = {
              x: chatbotNode.position.x + (chatbotNode.width || nodeWidth) + 50,
              y: chatbotNode.position.y,
            };
          }
        }
        // Set handle positions for reference node
        node.targetPosition = 'left';
        node.sourcePosition = 'right';
      });

      // Combine all nodes
      const allNodes = [...layoutedNodes, ...refNodes];

      return { nodes: allNodes, edges };
    },
    []
  );

  // Function to handle socket responses
  const handleSocketResponse = useCallback((data) => {
    let botNodeId = null;

    setNodes((prevNodes) => {
      let updatedNodes = [...prevNodes];
      let hasChanged = false;

      for (let i = updatedNodes.length - 1; i >= 0; i--) {
        if (updatedNodes[i].type === 'chatbot' && updatedNodes[i].data.isPlaceholder) {
          const newData = {
            ...updatedNodes[i].data,
            label: updatedNodes[i].data.label + (data.text_delta || ''),
            isPlaceholder: !data.done,
          };

          if (JSON.stringify(updatedNodes[i].data) !== JSON.stringify(newData)) {
            updatedNodes[i] = {
              ...updatedNodes[i],
              data: newData,
            };
            botNodeId = updatedNodes[i].id;
            hasChanged = true;
          }
          break;
        }
      }
      if (hasChanged) {
        nodesRef.current = updatedNodes;
        return updatedNodes;
      }
      return prevNodes; // No update if nothing has changed
    });

    if (data.done && data.citations && data.citations.length > 0) {
      const refNodeId = getNextId();

      // Create the ReferenceNode and position it relative to the chatbot node
      const refNode = {
        id: refNodeId,
        type: 'reference', // Use the ReferenceNode type
        data: { references: data.citations }, // Pass the citations to the node
        position: { x: 0, y: 0 }, // Position will be set by layout
      };

      // Add the ReferenceNode
      setNodes((prevNodes) => {
        const updatedNodes = [...prevNodes, refNode];
        nodesRef.current = updatedNodes;
        return updatedNodes;
      });

      // Connect the ReferenceNode to the chatbot node
      setEdges((prevEdges) => {
        const updatedEdges = [
          ...prevEdges,
          {
            id: `e${botNodeId}-${refNodeId}`,
            source: botNodeId,
            target: refNodeId,
            sourceHandle: 'right', // Specify the handle on the right side
            type: 'smoothstep',
            animated: true,
          },
        ];
        edgesRef.current = updatedEdges;
        return updatedEdges;
      });
    }
  }, []);

  // Initialize Socket Connection
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://gptharvard.uc.r.appspot.com', {
        transports: ['polling'],
        reconnectionAttempts: 5,
        timeout: 20000,
        path: '/socket.io',
        auth: {
          class_name: selectedClass, // Send class_name during connection
        },
      });
    }

    const socket = socketRef.current;
    socket.on('connect', () => {
      console.log('Server Connection');
      // Additional notification or actions can be added here
    });

    socket.on('final_response', (data) => {
      console.log('Receiving Response:', data); // Debug log
      handleSocketResponse(data);
    });

    socket.on('error', (error) => {
      console.error('Socket.IO Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
      socket.disconnect();
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected successfully on attempt ${attemptNumber}`);
    });

    socket.on('visualization_ready', (data) => {
      if (data.visualization_type) {
        console.log(`Visualization is ready: Type = ${data.visualization_type}`);
      } else {
        console.warn('Visualization type is missing in the received data:', data);
      }
      // Optional: Set a loading state or notify the user
    });

    // Listener for 'visualization_data'
    socket.on('visualization_data', (data) => {
      console.log('Received Visualization Data:', data);
      handleVisualizationData(data);
    });

    return () => {
      socket.off('connect');
      socket.off('final_response');
      socket.off('error');
      socket.off('reconnect_attempt');
      socket.off('connect_error');
      socket.off('reconnect');
      socket.off('visualization_ready');
      socket.off('visualization_data'); // October 14
    };
  }, [handleSocketResponse, selectedClass]);

  // Layout effect
  useEffect(() => {
    if (!reactFlowInstance) return;

    const nodesWithDimensions = reactFlowInstance.getNodes();
    const allNodesHaveDimensions = nodesWithDimensions.every((node) => node.width && node.height);

    if (allNodesHaveDimensions) {
      const { nodes: layoutedNodes } = getLayoutedElements(nodesWithDimensions, edgesRef.current);

      // Compare positions to prevent unnecessary updates
      const positionsChanged = nodesWithDimensions.some((node) => {
        const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
        return node.position.x !== layoutedNode.position.x || node.position.y !== layoutedNode.position.y;
      });

      if (positionsChanged) {
        setNodes((prevNodes) => {
          const updatedNodes = prevNodes.map((node) => {
            const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
            return {
              ...node,
              position: layoutedNode.position,
            };
          });
          nodesRef.current = updatedNodes;
          return updatedNodes;
        });
      }
    } else {
      // Wait and try again if dimensions are not available yet
      const timeoutId = setTimeout(() => {
        const nodesWithDimensions = reactFlowInstance.getNodes();
        const allNodesHaveDimensions = nodesWithDimensions.every((node) => node.width && node.height);

        if (allNodesHaveDimensions) {
          const { nodes: layoutedNodes } = getLayoutedElements(nodesWithDimensions, edgesRef.current);

          setNodes((prevNodes) => {
            const updatedNodes = prevNodes.map((node) => {
              const layoutedNode = layoutedNodes.find((n) => n.id === node.id);
              return {
                ...node,
                position: layoutedNode.position,
              };
            });
            nodesRef.current = updatedNodes;
            return updatedNodes;
          });
        }
      }, 50);

      return () => clearTimeout(timeoutId);
    }
  }, [nodes, edges, reactFlowInstance, getLayoutedElements]);

  // Logout function
  const logout = async () => {
    try {
      await auth.signOut(); // Sign out from Firebase
      navigate('/'); // Redirect to the landing page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Custom Node Components

  // UserNode component
  const UserNode = React.memo(({ data }) => (
    <div className="user-node">
      <div>{renderTextWithFormatting(data.label)}</div>
      <Handle type="source" position="bottom" />
    </div>
  ));

  // ChatbotNode component
  const ChatbotNode = React.memo(({ data }) => {
    const contentRef = useRef();

    return (
      <div className={`chatbot-node ${data?.hasVisual ? 'small-width' : ''}`}>
        <div className="chatbot-content" ref={contentRef}>
          {renderTextWithFormatting(data.label)}
        </div>
        {/* Icons at the bottom center */}
        <div className="chatbot-node-icons">
          <button
            className="chatbot-node-icon-button"
            onClick={() => copyToClipboard(contentRef.current)}
            title="Copy"
          >
            <FaCopy className="chatbot-node-icon copy-icon" />
          </button>
          <button
            className="chatbot-node-icon-button"
            onClick={() => flagContent(data.id, data.label)}
            title="Flag"
          >
            <FaFlag className="chatbot-node-icon flag-icon" />
          </button>
        </div>
        <Handle type="target" position="top" />
        <Handle type="source" position="right" id="right" />
      </div>
    );
  });

  // ReferenceNode component
  const ReferenceNode = React.memo(({ data }) => (
    <div className="reference-node">
      <div>{renderTextWithFormatting(`References: ${data.references.join(', ')}`)}</div>
      <Handle type="target" position="left" /> {/* Handle on the left side */}
    </div>
  ));

  // Initial Options Node Component
  const InitialOptionsNode = React.memo(({ data }) => {
    const handleOptionClick = useCallback(
      (optionText) => {
        data.onOptionClick(optionText);
      },
      [data.onOptionClick]
    );

    const optionCards = useMemo(
      () =>
        initialOptions.map((optionText, index) => (
          <OptionCard
            key={index}
            heading={
              index === 0
                ? 'Go over lecture and section material!'
                : index === 1
                ? 'Generate some custom visuals (Soon)!'
                : 'Clear up confusion and get PSET help!'
            }
            text={optionText}
            onClick={() => handleOptionClick(optionText)}
            imageSrc={index === 0 ? newImage1 : index === 1 ? newImage2 : newImage3}
          />
        )),
      [handleOptionClick]
    );

    return (
      <div className="initial-options-node">
        <div className="options-container">{optionCards}</div>
      </div>
    );
  });

  // OptionCard component
  const OptionCard = React.memo(({ heading, text, onClick, imageSrc }) => {
    const [displayedText, setDisplayedText] = useState('');
    const hasStarted = useRef(false);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === '/') {
          // Prevent the default browser search behavior
          event.preventDefault();
          // Focus on the input box
          document.querySelector('.floating-input').focus();
        }
      };

      // Add the event listener when the component mounts
      document.addEventListener('keydown', handleKeyDown);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    useEffect(() => {
      if (!hasStarted.current) {
        hasStarted.current = true;
        let index = 0;
        const interval = setInterval(() => {
          setDisplayedText((prev) => prev + text.charAt(index));
          index++;
          if (index >= text.length) {
            clearInterval(interval);
          }
        }, 30);
        return () => clearInterval(interval);
      }
    }, [text]);

    return (
      <div className="option-card" onClick={onClick}>
        {/* Add heading above the image */}
        <h3 className="option-card-heading">{heading}</h3>
        <img src={imageSrc} alt="Option" />
        <p>{displayedText}</p>
      </div>
    );
  });

  // VisualNode Component
  const VisualNode = ({ data, style }) => {
    const nodeStyle = style || {
      width: 500,
      height: 300,
      border: '1px solid #ccc',
      backgroundColor: '#fff',
    };
  
    const { plotData, visualizationType, funcExpression, plotMode } = data;
  
    const renderVisualization = () => {
      switch (visualizationType) {
        case 'generate_riemann':
          return (
            <Suspense fallback={<div>Loading Riemann Plot...</div>}>
              <RiemannSurfacePlot
                funcExpression={funcExpression}  // Pass function expression
                plotMode={plotMode}              // Pass plot mode
                real={plotData.real}
                imag={plotData.imag}
                real_w={plotData.real_w}
                imag_w={plotData.imag_w}
                colorScale={plotData.color_scale}
              />
            </Suspense>
          );
        default:
          return <div>Unsupported Visualization Type: {visualizationType}</div>;
      }
    };
  
    return (
      <div className="visual-node" style={nodeStyle}>
        {plotData ? renderVisualization() : <div className="visual-placeholder">No Data Available</div>}
        <div className="resize-handle" onMouseDown={(e) => data.onResizeStart(e, data.id)}></div>
        <Handle type="target" position="top" />
      </div>
    );
  };
  

  // Define nodeTypes
  const nodeTypes = {
    user: UserNode,
    chatbot: ChatbotNode,
    reference: ReferenceNode,
    visual: VisualNode,
    initialOptions: InitialOptionsNode,
  };

  // Function to copy content to clipboard
  const copyToClipboard = (contentElement) => {
    if (contentElement) {
      const text = contentElement.innerText;
      navigator.clipboard.writeText(text).then(
        () => {
          console.log('Text copied to clipboard');
        },
        (err) => {
          console.error('Failed to copy text: ', err);
        }
      );
    } else {
      console.error('Content element is invalid');
    }
  };

  // Function to flag content
  const flagContent = (id, content) => {
    console.log(`Content with ID ${id} has been flagged.`);
    console.log(`Flagged content: ${content}`);
  };

  // Function to handle visualization data
  const handleVisualizationData = useCallback((data) => {
    const { plot_data, thread_id, message_index, visualization_type } = data;

    // Generate a unique ID for the new visual node
    const visualNodeId = getNextId();

    // Prepare the new visual node
    const visualNode = {
  id: getNextId(),
  type: 'visual',
  data: {
          plotData: plot_data,  // This will be passed as initialPlotData
          funcExpression: plot_data.funcExpression || 'z**3', // Add function expression (default if missing)
          plotMode: plot_data.plotMode || 'real',             // Add plot mode (default to 'real')
          visualizationType: visualization_type,
        },
        position: { x: 0, y: 0 }, // Position will be set by layout
        threadId: thread_id,
        messageIndex: message_index,
      };

    setNodes((prevNodes) => {
      return [...prevNodes, visualNode];
    }); 

    // Find the originating chatbot node based on thread_id and message_index
    const originatingBotNode = nodesRef.current.find(
      (node) => node.type === 'chatbot' && node.data.threadId === thread_id && node.data.messageIndex === message_index
    );

    // Position the visual node relative to the chatbot node
    if (originatingBotNode) {
      visualNode.position = {
        x: originatingBotNode.position.x + 600, // Adjust based on your layout
        y: originatingBotNode.position.y,
      };
    } else {
      // Fallback position
      visualNode.position = { x: 300, y: 300 };
    }

    // Add the new visual node to the nodes state
    setNodes((prevNodes) => {
      const updatedNodes = [...prevNodes, visualNode];
      nodesRef.current = updatedNodes;
      return updatedNodes;
    });

    // Create an edge from chatbot to visual node
    if (originatingBotNode) {
      const newEdge = {
        id: `e${originatingBotNode.id}-${visualNodeId}`,
        source: originatingBotNode.id,
        target: visualNodeId,
        type: 'smoothstep',
        animated: true,
      };

      setEdges((prevEdges) => {
        const updatedEdges = [...prevEdges, newEdge];
        edgesRef.current = updatedEdges;
        return updatedEdges;
      });
    }

    // Trigger layout recalculation if necessary
    if (reactFlowInstance) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodesRef.current, edgesRef.current);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
  }, [reactFlowInstance, getLayoutedElements]);

  // Function to handle resize start (Placeholder: Implement your resizing logic)
  const handleResizeStart = (e, nodeId) => {
    // Implement resizing logic here
    console.log(`Resize started for node ID: ${nodeId}`);
  };

  return (
    <div className={`newgpt-container ${isNightMode ? 'night-mode' : ''}`}>
      {/* ReactFlow as full background */}
      <div className="flow-wrapper">
        <ReactFlow
          ref={flowRef}
          nodes={nodes}
          edges={edges}
          onConnect={(params) => setEdges((eds) => addEdge(params, eds))}
          transform={transform}
          zoomOnScroll={true}
          zoomOnPinch={true}
          panOnScroll={true}
          nodeTypes={nodeTypes}
          fitView
          onInit={(instance) => {
            setReactFlowInstance(instance);
          }}
          className="reactflow-background"
        >
          <Background color={isNightMode ? '#ffffff' : '#000000'} gap={16} variant="dots" />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </div>
      <div className="sidebar-menu">
        <div className="logo-placeholder">
          <img src={getClassLogo()} alt="Logo" className="logo-image" />
        </div>

        {/* Greeting and roles display */}
        <h2 className="centered">Hello, {userInfo.firstName}</h2>
        <h4 className="centered">
          Role: <strong>{userInfo.roles.join(', ')}</strong> in{' '}
          {userInfo.courses.length > 0 ? userInfo.courses.join(', ') : 'No courses assigned.'}
        </h4>

        <button className="menu-button" onClick={() => setShowSettingsModal(true)}>
          🔧 Settings
        </button>

        <button className="menu-button" onClick={openPrivacyOverlay}>
          🔒 Privacy Policy
        </button>

        <button
          className="menu-button"
          onClick={() =>
            (window.location.href = 'https://edstem.org/us/courses/58082/discussion/5331907')
          }
        >
          🐞 Report Bugs and Request Features
        </button>

        <button className="logout-button" onClick={logout}>
          Logout
        </button>
      </div>

      {/* Settings Modal */}
      {showSettingsModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Settings</h2>
            <button className="modal-button" onClick={toggleNightMode}>
              {isNightMode ? '🌞 Light Mode' : '🌙 Night Mode'}
            </button>
            <button className="modal-button" onClick={() => setShowSettingsModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}
      <div className="floating-bar">
        <input
          type="text"
          className="floating-input"
          placeholder="Type your question here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && input.trim()) {
              event.preventDefault();
              addUserMessage();
            }
          }}
        />
        <button className="floating-button" onClick={() => addUserMessage(input)}>
          <FaPaperPlane />
        </button>

        <button
          className="refresh-button"
          onClick={() => {
            setNodes([
              {
                id: 'initial-options',
                type: 'initialOptions',
                data: { onOptionClick: handleOptionClick },
                position: { x: 0, y: 0 },
              },
            ]);
            setEdges([]);
            nextIdRef.current = 1;
          }}
        >
          <FaSyncAlt />
        </button>
      </div>
    </div>
  );
}

export default NewerGPT;
